import classNames from 'classnames'

import useAppContext from 'app/store/useAppContext'
import { AffirmationForm } from 'features/affirmation/form/form'
import { useIsNoAnswers } from 'features/questions'
import InvestImg from 'shared/assets/images/contacts/InvestImg.webp'

import { contactsData } from '../model/contacts'

import cls from './Contacts.module.scss'

export const ContactsPage = () => {
  const {
    store: { isMobile, isMobileSmall, user },
    dispatch,
  } = useAppContext()

    useIsNoAnswers()

  const modalTitle = 'Оставьте Ваши контакты,\n' + 'чтобы открыть инвест-счет'

  return (
    <section className={classNames(cls.contacts)} id="contacts">
      <div
        className={classNames('container__desktop', cls.contacts__container)}
      >
        {!isMobileSmall && (
          <div className={cls.left_block}>
            <h2>Ваш подарок</h2>
            <h3>{contactsData.giftSum}</h3>
            {!isMobile && <img src={InvestImg} alt="InvestImg" />}
            <p>К сумме первого депозита</p>
          </div>
        )}
        <div className={cls.right_block}>
          <AffirmationForm user={user} dispatch={dispatch} title={modalTitle} />
        </div>
      </div>
    </section>
  )
}
