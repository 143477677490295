import { BeforeMaskedStateChangeStates } from 'react-input-mask'

import { PAST_BUG_PHONE, PAST_BUG_8_PHONE, PHONE_TITLE } from '../_model/constants'

export const phoneMaskChange = ({
                                    currentState,
                                    nextState,
                                }: BeforeMaskedStateChangeStates) => {

    const replacePastPhone = (BUG_PHONE: string) => {
        return {
            ...currentState,
            value: currentState.value.replace(BUG_PHONE, PHONE_TITLE),
        }
    }

    const IS_PAST_BUG_PHONE = currentState?.value.includes(PAST_BUG_PHONE)
    const IS_PAST_BUG_8_PHONE = currentState?.value.includes(PAST_BUG_8_PHONE)

    if (IS_PAST_BUG_PHONE) return replacePastPhone(PAST_BUG_PHONE)
    if (IS_PAST_BUG_8_PHONE) return replacePastPhone(PAST_BUG_8_PHONE)

    return nextState
}
