import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import type { ISearchParameters } from 'entities/user/model/user'
import useAppContext from 'app/store/useAppContext'
import { setUserSearchParams } from 'app/store/actions'

const getQueryParams = () => {
  const search = new URLSearchParams(document.location.search)

  return {
    external_id: search.get('external_id') || '',
    metric: search.get('metric') || '',
    gaid: search.get('gaid') || '',
    sub1: search.get('sub1') || '',
    sub2: search.get('sub2') || '',
    sub3: search.get('sub3') || '',
    sub4: search.get('sub4') || '',
    sub5: search.get('sub5') || '',
    sub6: search.get('sub6') || '',
    sub7: search.get('sub7') || '',
    apps_id: search.get('apps_id') || '',
    devKey: search.get('devKey') || '',
    bundle: search.get('bundle') || '',
    status: search.get('status') || '',
  }
}
const setQueryParams = (searchParams: ISearchParameters) => {
  const params = new URLSearchParams(window.location.search)
  Object.entries(searchParams).forEach(([key, value]) => {
    params.set(key, value)
  })
  window.history.replaceState({}, '', `${window.location.pathname}?${params}`)
}
const replaceSearchParams = (
    setSearchParams: Dispatch<SetStateAction<ISearchParameters>>,
) => {
  const newSearchParams: ISearchParameters = getQueryParams()

  if (newSearchParams?.external_id) {
    setSearchParams((prevState) => ({ ...prevState, ...newSearchParams }))
  }
}

export const useSetUserSearchParams = () => {
  const { dispatch } = useAppContext()
  const { pathname, search } = useLocation()

  const [searchParams, setSearchParams] =
      useState<ISearchParameters>(getQueryParams())

  useEffect(() => {
    replaceSearchParams(setSearchParams)
  }, [])
  useEffect(() => {
    replaceSearchParams(setSearchParams)
  }, [pathname, search])
  useEffect(() => {
    dispatch(setUserSearchParams(searchParams))
    setQueryParams(searchParams)
  }, [dispatch, searchParams])
}
